<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const { authenticated } = useAuth()
const { headerTheme } = useHeaderNavigation()
const { locale } = useI18n<unknown, Locale>()
const { properties } = useUserHouses()
const { user } = useUser()
const localePath = useLocalePath()
const { trackEvent } = useTracking()

const transparentColor = computed(() => headerTheme.value === 'transparent')
const initial = computed(
  () => `${user.value.firstName?.at(0)}${user.value.lastName?.at(0)}`,
)

const accountLink = computed(() => {
  const accountLoggedLink = properties.value.length
    ? localePath({
        name: 'myaccount-property-name-rentals',
        params: { name: properties.value[0].slug[locale.value] },
      })
    : localePath('myaccount-bookings')

  return authenticated.value ? accountLoggedLink : localePath('login')
})

const handleTrack = (connectionType: string) => {
  trackEvent({
    event: 'header_connection_click',
    connection_type: connectionType,
  })
}
</script>

<template>
  <div v-if="!authenticated" class="group relative">
    <button
      :class="[
        'flex items-center rounded border px-3 py-2 text-md',
        { 'border-white text-white group-hover:bg-white/10': transparentColor },
        {
          'border-secondary-400 text-secondary-400 group-hover:bg-secondary-100':
            !transparentColor,
        },
      ]"
      type="button"
    >
      {{ $t('header.signIn') }}
      <BaseIcon name="moreVert" :size="1" class="ml-1" />
    </button>

    <div class="absolute right-0 z-fixed hidden group-hover:block">
      <div
        :class="[
          'mt-2 w-[188px] rounded p-6 shadow-normal ',
          { 'bg-gray-700/85': transparentColor },
          { 'bg-white': !transparentColor },
        ]"
      >
        <NuxtLink
          :class="[
            'mb-6 block text-md',
            { 'text-white': transparentColor },
            { 'text-gray-700': !transparentColor },
          ]"
          :to="localePath('login')"
          @click.prevent="handleTrack('Client or owner')"
        >
          {{ $t('header.customerOrOwner') }}
        </NuxtLink>
        <NuxtLink
          :class="[
            'block text-md',
            { 'text-white': transparentColor },
            { 'text-gray-700': !transparentColor },
          ]"
          :to="localePath('login')"
          @click.prevent="handleTrack('Travel agent')"
        >
          {{ $t('header.travelAgent') }}
        </NuxtLink>
      </div>
    </div>
  </div>
  <NuxtLink
    v-else
    :class="[
      'flex size-8 items-center justify-center rounded-full text-xs font-semibold uppercase !no-underline',
      {
        'bg-gray-100 text-secondary-400': !transparentColor,
        'bg-white/20 text-white': transparentColor,
      },
    ]"
    :to="accountLink"
  >
    {{ initial }}
  </NuxtLink>
</template>
