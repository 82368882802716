import { User } from "lc-repository/dist/entities";
import { LoginWithProvidersPresentation } from "../usecases/LoginWithProviders/interfaces/LoginWithProvidersPresenter";
import { LoginWithProvidersRepository } from "../usecases/LoginWithProviders/interfaces/LoginWithProvidersRepository";
import { LoginWithProvidersProps } from "../usecases/LoginWithProviders/types";
import { NavType } from "../adapters/ViewModel";
import { ApiError } from "types";

type MobileRepositoryHandlerAsync = Pick<
  LoginWithProvidersProps,
  "email" | "uuid" | "provider"
>;

type MobileSuccessHandler = {
  presenter: LoginWithProvidersPresentation;
  user: User<{ serialized: true }> | null;
  firstLaunch?: boolean;
  redirect?: NavType;
};

type MobileErrorsHandler = {
  presenter: LoginWithProvidersPresentation;
  errors: ({ status: number } & ApiError) | null;
};

export class MobileAppLoginAddon {
  constructor(private repository: LoginWithProvidersRepository) {}

  private async repositoryHandlerAsync({
    email,
    uuid,
    provider,
  }: MobileRepositoryHandlerAsync) {
    const response = await this.repository.signIn({
      user: { email, uuid, provider },
    });

    const user = response.data
      ? (response.data as unknown as User<{ serialized: true }>)
      : null;

    const errors = response.errors
      ? (response.errors as unknown as { status: number })
      : null;

    return { user, errors };
  }

  private successHandler({
    presenter,
    user,
    firstLaunch,
    redirect,
  }: MobileSuccessHandler) {
    if (user) {
      if (user.incompleteSignup === true) {
        presenter.displayNavigation("SignUp");
      } else if (firstLaunch)
        presenter.displayNavigation("EnableNotifications");
      else presenter.displayNavigation(redirect || "App");
    }
  }

  private errorsHandler({ presenter, errors }: MobileErrorsHandler) {
    if (errors?.status === 422) presenter.displayNavigation("SignUp");
    if (
      (errors?.response?.data?.errors?.[0] as { user: string })?.user ===
      "incomplete_signup"
    )
      presenter.displayNavigation("SignUp");
    else if (errors?.status === 404 || errors?.status === 400)
      presenter.displayToast({
        type: "error",
        msg: "tierPartConnect.accountNotFound",
      });
  }

  protected async loginOnMobileAppAsync(
    presenter: LoginWithProvidersPresentation,
    mobileProps: LoginWithProvidersProps
  ) {
    const { email, uuid, provider, firstLaunch, redirect } = mobileProps;

    const { user, errors } = await this.repositoryHandlerAsync({
      email,
      uuid,
      provider,
    });

    this.successHandler({ presenter, user, firstLaunch, redirect });
    this.errorsHandler({
      presenter,
      errors: errors as MobileErrorsHandler["errors"],
    });
  }
}
