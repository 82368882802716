<script setup lang="ts">
import TheHeaderNavigationIconsMobile from '~/components/TheHeader/Mobile/TheHeaderNavigationIconsMobile.vue'

const { t } = useI18n()
const { headerMobile, setMenuIsOpen, setSearchIsOpen, setSlidePageActive } =
  useHeaderMobile()
const { headerTheme, pageTheme, setTemporaryTheme } = useHeaderNavigation()
const { setLock } = useLockBody()

const menuMobileSlidePageActive = computed(
  () => headerMobile.value.slidePageActive,
)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)
const showSlideMenu = computed({
  get() {
    return headerMobile.value.menuIsOpen
  },
  set(value) {
    setMenuIsOpen(value)
  },
})

const slidePageActive = computed({
  get() {
    return menuMobileSlidePageActive.value
  },
  set(value) {
    setSlidePageActive(value)
  },
})
const pageSlided = computed(() => Boolean(slidePageActive.value))
const titleSlidingPage = computed(() => t(`header.${slidePageActive.value}`))

watch(
  () => showSlideMenu.value,
  (val) => {
    setLock(val)

    // close search when click burger menu
    if (val) setSearchIsOpen(false)

    if (!val) closeMenu()

    if (pageTheme.value !== 'white') {
      setTemporaryTheme(val || searchIsOpen.value ? 'white' : '')
    }
  },
)

const closeMenu = () => {
  // to avoid side effect animation on close
  setTimeout(() => {
    setSlidePageActive(null)
  }, 500)
}
const previousSlide = () => {
  setSlidePageActive(null)
}
const toggleNav = (value: boolean) => {
  setMenuIsOpen(value)
}
</script>

<template>
  <header :class="['the-header-mobile', { white: headerTheme === 'white' }]">
    <transition-group
      class="the-header-mobile__navbar relative z-fixed flex justify-between"
      name="fade"
      tag="div"
    >
      <the-menu-mobile-screen-topbar
        v-if="pageSlided && showSlideMenu"
        key="main-menu"
        v-model="showSlideMenu"
        :name="titleSlidingPage"
        class="absolute px-4 py-6"
        @previous-step="previousSlide"
      />

      <div
        v-else
        key="secondary-menu"
        class="absolute flex w-full justify-between px-4 py-6 sm:px-6"
      >
        <the-header-logo @on-logo-click="toggleNav(false)" />
        <the-header-navigation-icons-mobile v-model="showSlideMenu" />
        <div
          :class="[
            'search-underline absolute bottom-px right-[86px] h-px w-[26px]',
            { active: searchIsOpen },
          ]"
          :style="{ 'background-color': 'var(--header-text-color)' }"
        />
      </div>
    </transition-group>
  </header>
</template>

<style>
.the-header-mobile.white:before {
  content: '';
  transition: opacity 300ms ease-in-out 200ms;
  @apply opacity-100;
}

.the-header-mobile:before {
  content: '';
  height: var(--header-height);
  box-shadow: var(--header-mobile-shadow);
  transition: opacity 300ms ease-in-out;
  @apply absolute top-0 w-full opacity-0 z-fixed;
}

.the-header-mobile__navbar {
  height: var(--header-height);
  background-color: var(--header-bg-color);
}

.white .the-header-mobile__navbar {
  transition: background-color 500ms ease-in 200ms;
}

.the-header-mobile .fade-enter-active,
.the-header-mobile .fade-leave-active {
  opacity: 1;
  transition: opacity 0.4s;
}

.the-header-mobile .fade-enter,
.the-header-mobile .fade-leave-to {
  opacity: 0;
}

.the-header-mobile .search-underline {
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.the-header-mobile .search-underline.active {
  transform: scaleX(1);
}
</style>
