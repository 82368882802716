export const useLockBody = () => {
  const pageYOffset = useState('lock-body', () => 0)

  const setLock = (lock: boolean) => {
    if (lock) {
      pageYOffset.value = window.pageYOffset

      document.body.style.marginTop = `-${pageYOffset.value}px`
      document.body.classList.add(
        'fixed',
        'left-0',
        'right-0',
        'overflow-hidden',
      )
    } else {
      document.body.style.marginTop = ''
      document.body.classList.remove(
        'fixed',
        'left-0',
        'right-0',
        'overflow-hidden',
      )

      window.scrollTo(0, pageYOffset.value)
    }
  }

  return { setLock, pageYOffset }
}
