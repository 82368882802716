// API V1
import auth from '~/repository/services/v1/auth/index'
import callbackRequests from '~/repository/services/v1/callbackRequests'
import clientGeneralRentalCondition from '~/repository/services/v1/clientGeneralRentalCondition'
import destinations from '~/repository/services/v1/destinations/index'
import experiences from '~/repository/services/v1/experiences'
import housePeriods from '~/repository/services/v1/housePeriods'
import houses from '~/repository/services/v1/houses/index'
import housesBookings from '~/repository/services/v1/houses/bookings'
import ownerGeneralRentalCondition from '~/repository/services/v1/ownerGeneralRentalCondition'
import paymentTransactions from '~/repository/services/v1/paymentTransactions'
import requests from '~/repository/services/v1/requests'
import termsOfService from '~/repository/services/v1/termsOfService'
import travelAgentGeneralRentalCondition from '~/repository/services/v1/travelAgentGeneralRentalCondition'
import userClientGeneralRentalCondition from '~/repository/services/v1/user/clientGeneralRentalCondition'
import userCompanies from '~/repository/services/v1/user/companies'
import userDocuments from '~/repository/services/v1/user/documents'
import userHouses from '~/repository/services/v1/user/houses/index'
import userHousesCalendarExports from '~/repository/services/v1/user/houses/calendarExports'
import userHousesCalendarImports from '~/repository/services/v1/user/houses/calendarImports'
import userHousesContractInformations from '~/repository/services/v1/user/houses/contractInformations'
import userHousesPeriods from '~/repository/services/v1/user/houses/periods'
import userOwnerGeneralRentalCondition from '~/repository/services/v1/user/ownerGeneralRentalCondition'

export default {
  v1: {
    auth,
    callbackRequests,
    clientGeneralRentalCondition,
    destinations,
    experiences,
    housePeriods,
    houses,
    housesBookings,
    ownerGeneralRentalCondition,
    paymentTransactions,
    requests,
    termsOfService,
    travelAgentGeneralRentalCondition,
    userClientGeneralRentalCondition,
    userCompanies,
    userDocuments,
    userHouses,
    userHousesCalendarExports,
    userHousesCalendarImports,
    userHousesContractInformations,
    userHousesPeriods,
    userOwnerGeneralRentalCondition,
  },
}
