<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/components/TheFooter/form/groupField'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'
import type { MutationType } from '../BaseForm/types'

const { $lcRepositories } = useNuxtApp()
const { t } = useI18n()
const { trackEvent } = useTracking()
const { user } = useUser()

const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('footer.send'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.color = 'primary'
  btnPrimary.attrs.class = 'btn-primary'
}

const success = ref(false)
const initialData = reactive({
  email: user.value.email,
})

const groupFieldsForm = reactive(groupFields())

const configForm = reactive({
  fields: { email: { attr: { disabled: Boolean(user.value.email) } } },
})

const mutation: MutationType<{ email: string }> = (data) => ({
  request: (formData) =>
    $lcRepositories.newsletterEmails.subscribeNewsletter(formData),
  input: {
    email: data.email,
  },
})

const onSubmit: () => SendMutationType['onSubmit'] = () => ({
  success: () => {
    initialData.email = ''
    success.value = true
    trackEvent({
      event: 'newsletter_subscription',
      label: 'footer',
      post_alert: false,
    })
  },
  error: (err) => {
    console.warn(err)
  },
})
</script>

<template>
  <div>
    <div class="font-monospace m-0 text-4xl text-white">
      {{ $t('footer.join_newsletter') }}
    </div>
    <p class="text-small text-white">
      {{ $t('footer.subscribe_newsletter') }}
    </p>

    <BaseForm
      ref="formNewsletter"
      class="flex items-center"
      :active-modal-errors="false"
      :active-modal-save-or-quit="false"
      :button-primary="btnPrimary"
      :config-form="configForm"
      :group-fields="groupFieldsForm"
      :initial-data="initialData"
      :mutation="mutation"
      :on-submit="onSubmit"
      id-form="newsletter-form"
      container-form-class="w-full"
      container-button-class="flex whitespace-nowrap w-auto justify-end h-full"
    />

    <div v-if="success" class="text-small mt-1 text-white">
      {{ $t('footer.success_newsletter') }}
    </div>
  </div>
</template>

<style>
#newsletter-form {
  @apply pb-4 h-[67px];
}
#newsletter-form .lc-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
