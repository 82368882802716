<script setup lang="ts">
import { phoneByLanguage } from '~/helpers/getPhoneByLanguage'

const config = useRuntimeConfig()
const { user } = useUser()

defineEmits<{
  click: [MouseEvent]
}>()
withDefaults(
  defineProps<{
    color?: 'primary' | 'primary-light'
  }>(),
  {
    color: 'primary',
  },
)

const phoneNumber = computed(() =>
  phoneByLanguage({
    config,
    language: user.value.country,
  }),
)
</script>

<template>
  <BaseButton
    :color="color"
    :href="`tel:${phoneNumber}`"
    variant="link"
    @click="$emit('click', $event)"
  >
    {{ phoneNumber }}
  </BaseButton>
</template>
