import type { StateModal } from '~/types/types'

export const useMeetingScheduler = (container?: Ref<HTMLDivElement | null>) => {
  const stateModal = useState<StateModal>('state-modal')
  const { meta } = useRoute()
  const { trackEvent } = useTracking()
  const schedulerIsOpen = computed(() => stateModal.value.schedulerIsOpen)
  // As the script is loaded inside a modale, it needs recomputing at every render
  const script = computed(() =>
    container
      ? useScript(
          'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js',
          {
            trigger: useScriptTriggerElement({
              trigger: 'visible',
              el: container.value,
            }),
          },
        )
      : null,
  )

  const openScheduler = () => {
    trackEvent({
      category: 'General',
      event: 'meeting_inquiry_started',
      page_type: (meta?.gtmAdditionalEventData as any)?.page_type ?? 'other',
    })
    stateModal.value.schedulerIsOpen = true
  }
  const closeScheduler = () => {
    stateModal.value.schedulerIsOpen = false
  }

  // Only watch changes to the container when it's defined
  watch(
    () => container?.value,
    (el) => script.value?.[el ? 'load' : 'remove'](),
  )

  return {
    closeScheduler,
    openScheduler,
    schedulerIsOpen,
  }
}
