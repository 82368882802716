export type StoredData<T> = {
  data: T
  timestamp: number
}

export const STORAGE_PREFIX = 'lc-site__'

export const getStoredData = async <T = unknown>(
  storageKey: string,
  request: () => Promise<T>,
  maxAgeInDays = 7,
) => {
  if (import.meta.server) return {} as T

  const storedData = JSON.parse(
    localStorage.getItem(STORAGE_PREFIX.concat(storageKey)) ?? '{}',
  ) as StoredData<T>
  if (
    storedData.timestamp &&
    $dayjs().diff(storedData.timestamp, 'day') <= maxAgeInDays
  ) {
    return storedData.data
  }

  const data = await request().catch(console.error)
  if (data)
    localStorage.setItem(
      STORAGE_PREFIX.concat(storageKey),
      JSON.stringify({ data, timestamp: new Date().getTime() }),
    )
  return data
}

export const getStoredList = <T = unknown>(
  namespace: string,
  exceptItem = { id: '' },
  maxItems = 6,
) =>
  import.meta.server
    ? []
    : (
        JSON.parse(
          localStorage.getItem(STORAGE_PREFIX.concat(namespace)) ?? '[]',
        ) as StoredData<T & { id: string }>[]
      )
        .sort((a, b) => b.timestamp - a.timestamp)
        .filter(({ data }) => data.id !== exceptItem.id)
        .slice(0, maxItems)

export const setStoredList = (
  namespace: string,
  data: { id: string },
  maxItems = 12,
) => {
  if (!import.meta.server)
    localStorage.setItem(
      STORAGE_PREFIX.concat(namespace),
      JSON.stringify(
        [
          { data, timestamp: Date.now() },
          ...getStoredList(namespace, data, maxItems),
        ].slice(0, maxItems),
      ),
    )
}
